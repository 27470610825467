import React from "react";
import { EditTextField } from "core/components/inputWithAccessControl";
import Joi from "joi";
import { SearchAndAddCard } from "core/cards/searchAndAddCard";
import { useLoading } from "core/hooks/loadingHook";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { searchGateway } from "./gatewayCrudService";
import { findAll } from "./gatewayCrudService";


export const GatewaySearch = ({ onSearchResults, onAddNew }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const schema = Joi.object({
    name: Joi.string().trim().label("name").allow(""),
    // name: Joi.string().trim().label("Name").allow(""),
  });
  const submitCallback = async (event) => {
    const { name } = inputs;
    try {
      startLoading();
      const body = {
        // name: _.isNil(name) ? "" : name,
        name: _.isNil(name) ? "" : name,

      };
      // console.log(name);
      // console.log(body);
      const { data: gateways } = await searchGateway(body);
      onSearchResults(gateways);
      if (gateways.length > 0) {
        toast.success("Data fetched");
      } else {
        toast.warn("No data found");
      }

      stopLoading();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        toast.warn("No data found");
      } else {
        toast.error(
          "Error fetching gateway data. Please refer console for detailed error"
        );
      }
      stopLoading();
    }
  };
  const defaultInputs = {};
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const handleFindAll = async () => {
    try {
      startLoading();
      // console.log(name);
      // console.log(body);
      const { data: gateways } = await findAll();
      onSearchResults(gateways);
      // console.log(gateways);
      if (gateways.length > 0) {
        toast.success("Data fetched");
      } else {
        toast.warn("No data found");
      }

      stopLoading();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        toast.warn("No data found");
      } else {
        toast.error(
          "Error fetching gateway data. Please refer console for detailed error"
        );
      }
      stopLoading();
    }
  };

  const handleAddNew = () => {
    onAddNew();
  };

  return (
    <React.Fragment>
      <SearchAndAddCard
        formName={"Search Gateway"}
        onSubmit={handleSubmit}
        onFormReset={resetInput}
        onFindAll={handleFindAll}
        onAddNew={handleAddNew}
        submitAccess={true}
        submitText={"Search"}
      >
        <div className="columns is-multiline">

          <div className="column is-one-third">
            <EditTextField
              identifier="name"
              labelName="Name"
              handleInputChange={handleInputChange}
              inputs={inputs}
              errors={errors}
              editAccess={true}
            />
          </div>
          {/* /.column */}
          {/* <div className="column is-one-third">
            <EditTextField
              identifier="name"
              labelName="Name"
              handleInputChange={handleInputChange}
              editAccess={true}
              inputs={inputs}
              errors={errors}
            ></EditTextField>
          </div> */}
          {/* /.column */}
        </div>
        {/* /.columns */}
      </SearchAndAddCard>
      {loaderContainer}
    </React.Fragment>
  );
};
