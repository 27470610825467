import { UserCrud } from "features/userCrud/userCrud";
import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";

export const UserAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"User"}
          description={"Interface for handling users"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Nodes"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            <UserCrud />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
