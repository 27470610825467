import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";
import { addParameter } from "./parameterCrudService";
import { editParameter } from "./parameterCrudService";

export const ParameterForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {
  
  const { showError } = useAxiosErrorHandlingHook();
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    id: Joi.number().label("Id").positive().integer().allow(""),
    name: Joi.string().trim().label("Name").required(),
    displayName: Joi.string().trim().label("Display Name").required(),
    lowerLimit: Joi.number().label("Lower Limit").required(),
    upperLimit: Joi.number().label("Upper Limit").positive() .required(),
    unit: Joi.string().trim().label("Unit").required(),


   });
  const accessToEdit = true;
  const submitCallback = async (event) => {
    const {
      id,
      name,
      displayName,
      lowerLimit,
      upperLimit,
      unit
      } = inputs;
    try {
      startLoading();

      const body = {
        id,
        name,
        displayName,
        lowerLimit,
        upperLimit,
        unit
       
      };

      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: zone } = await addParameter(body);
        onUpdateSuccess(zone);
        toast.success("Parameter Added Successfully");
      } else {
        const { data: zone } = await editParameter(id, body);
        onUpdateSuccess(zone);
        toast.success("Parameter Edited Successfully");
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      const toastMessage="Error adding / editing parameter details";
      showError(error,toastMessage);

      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);
      handleInputChange("name", selectedData["name"]);
      handleInputChange("displayName", selectedData["displayName"]);
      handleInputChange("lowerLimit", selectedData["lowerLimit"]);
      handleInputChange("upperLimit", selectedData["upperLimit"]);
      handleInputChange("unit", selectedData["unit"]);
 } else {
      resetInput();
      handleInputChange("id", "");
    }
  }, [selectedData]);

  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update Parameter"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="name"
            labelName="Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="displayName"
            labelName="Display Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="lowerLimit"
            labelName="Lower Limit"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="upperLimit"
            labelName="Upper Limit"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="unit"
            labelName="Unit"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
       </div>
     </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
