import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchNode(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes/search/company/${body.name}`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes`, {
      params: body,
    });
  }

  export function addNode(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/nodes`, body);
  }
  
  export function editNode(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/nodes/${id}`, body);
  }

  export function deleteNode(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/nodes/delete/${id}`);
  }
  
  export function fetchAllNodes() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes`);
  }

  export function saveAssignZone(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/nodes/assign`, body);
  }

  export function deleteAssignZone(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/nodes/assign/remove`, body);
  }

  export function getAssignZone(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes/assign/${id}`);
  }

  export function saveAssignGateway(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/nodes/gateways/assign`, body);
  }

  export function deleteAssignGateway(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/nodes/assign/gateways/remove`, body);
  }

  export function getAssignGateway(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes/assign/gateways/${id}`);
  }
  

  export function saveNodeParameters(id,body) {
    const base = globalConfig.get().apiEndpoint;
    const headers = {
      'Content-Type': 'application/json'
    };
    return http.put(`${base}/nodes/parameters/add`, body, {headers});
  }

  export function getAssignParameters(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/nodes/search/parameters/${id}`);
  }

  

