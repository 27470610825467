import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchPermission(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/permissions/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/permissions`, {
      params: body,
    });
  }

  export function addPermission(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/permissions`, body);
  }
  
  export function editPermission(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/permissions/${id}`, body);
  }

  export function deletePermission(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/permissions/delete/${id}`);
  }
  
  export function fetchAllpermission() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/permissions`);
  }

