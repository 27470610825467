import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { NodeCrud } from "features/node/nodeCrud";
  
export const NodeAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Node"}
          description={"Interface for handling  Nodes"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Node"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            < NodeCrud />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
