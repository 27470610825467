import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchZone(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/zones/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/zones`, {
      params: body,
    });
  }

  export function addZone(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/zones`, body);
  }
  
  export function editZone(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/zones/${id}`, body);
  }

  export function deleteZone(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/zones/delete/${id}`);
  }
  
  export function fetchAllZones() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/zones`);
  }

