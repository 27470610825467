import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { deleteGateway } from "features/gatewayCrud/gatewayCrudService";
import { GatewaySearch } from "features/gatewayCrud/gateway__search";
import { GatewayTable } from "features/gatewayCrud/gateway__table";
import { GatewayForm } from "features/gatewayCrud/gateway__form";

export const GatewayCrud = () => {
  const [gateways, setGateways] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setGateways(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

   // Delete Action
   const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: gateway } = await deleteGateway(data["id"]);
      toast.success("Gateway deleted successfully");
      const newList = [...gateways];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setGateways(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting gateway");
    }
  };
  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(gateways, { id: selectedId });
      const updatedDataList = [...gateways];
      updatedDataList[index] = updatedData;
      setGateways(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...gateways];
      newList.push(updatedData);
      setGateways(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

 

  return (
    <React.Fragment>
      <GatewaySearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <GatewayTable
        data={gateways}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <GatewayForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
