import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { UserTable } from "./user__table";
import { UserSearch } from "./user__search";
import { UserForm } from "./user__form";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { deleteUser } from "./userCrudService";
import { FeatureForm } from "./user__feature";
import { NodeForm } from "./user__node";

export const UserCrud = () => {
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setUsers(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

  // Delete Action
  const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: company } = await deleteUser(data["id"]);
      toast.success("User deleted successfully");
      const newList = [...users];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setUsers(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting company");
    }
  };

  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(users, { id: selectedId });
      const updatedDataList = [...users];
      updatedDataList[index] = updatedData;
      setUsers(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...users];
      newList.push(updatedData);
      setUsers(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

  // Feature Modal
  const [featureModal, openFeatureModal, closeFeatureModal] = useModal(false);
  const handleFeatureEdit = (data) => {
    setAction("FEATURE_EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFeatureModal();
  };

  // // Site Modal
  // const [siteModal, openSiteModal, closeSiteModal] = useModal(false);
  // const handleSiteEdit = (data) => {
  //   setAction("SITE_EDIT");
  //   setSelectedId(data["id"]);
  //   setSelectedInput(data);
  //   openSiteModal();
  // };

  // Node Modal
  const [nodeModal, openNodeModal, closeNodeModal] = useModal(false);
  const handleNodeEdit = (data) => {
    setAction("NODE_EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openNodeModal();
  };

  return (
    <React.Fragment>
      <UserSearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <UserTable
        data={users}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onFeatureEdit={handleFeatureEdit}
        onNodeEdit={handleNodeEdit}


      />
      <UserForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
      <FeatureForm
        open={featureModal}
        onClose={closeFeatureModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
       <NodeForm
        open={nodeModal}
        onClose={closeNodeModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
