import { FormCard } from "core/cards/formCard";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import { useMasterListState } from "features/masterList/masterListProvider";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { addUser } from "./userCrudService";
import { editUser } from "./userCrudService";
import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";

export const UserForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {
  const { companyList, siteList } = useMasterListState();
  const { showError } = useAxiosErrorHandlingHook();


   // Role Options
  const roleOptionArray = [
    "ADMIN",
    "USER",
   ];
  const roleOptions = addDefaultOption(formatArrayToOptions(roleOptionArray));

  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    id: Joi.string().trim().label("Id").allow("").required(),
    loginId: Joi.string().trim().label("Login Id").required(),
    // password: Joi.string().trim().min(3).max(30).label("Password").optional(),
    name: Joi.string().trim().label("Name").required(),
   
    contactNumber: Joi.alternatives().label("Contact Number").try(
      Joi.string()
        .pattern(/^[0-9]{10}$/)
        .label('Mobile Number')
        .required()
        .messages({
          'string.pattern.base': 'Mobile Number must be a 10-digit number',
          'any.required': 'Mobile Number is required',
        }),
      Joi.string()
        .pattern(/^[0-9]{2,5}-[0-9]{6,8}$/)
        .label('Landline Number')
        .required()
        .messages({
          'string.pattern.base': 'Landline Number must be in the format XX-XXXXXXX or XXX-XXXXXXX',
          'any.required': 'Landline Number is required',
        })
    ),
    email: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .label("Email"),
    role: Joi.string().trim().label("Role").required(),
   

  });
  const accessToEdit = true;
  const submitCallback = async (event) => {
    const {
      id,
      name,
      loginId,
      password,
      contactNumber,
      email,
      role,
     

    } = inputs;
    try {
      startLoading();

      const body = {
        id,
        name,
        loginId,
        password,
        contactNumber,
        email,
        role,
       
      };

      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: user } = await addUser(body);
        onUpdateSuccess(user);
        toast.success("User Added Successfully");
      } else {
        const { data: user } = await editUser(id, body);
        onUpdateSuccess(user);
        toast.success("User Edited Successfully");
      }
      //   console.log(companies);
      stopLoading();
    } catch (error) {
      console.log(error);
      const toastMessage="Error adding / editing user details";
      showError(error,toastMessage);

      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);
      handleInputChange("name", selectedData["name"]);
      handleInputChange("loginId", selectedData["loginId"]);
      handleInputChange("contactNumber", selectedData["contactNumber"]);
      handleInputChange("email", selectedData["email"]);
      handleInputChange("role", selectedData["role"]);
     


    } else {
      resetInput();
      handleInputChange("id", "");
    }
  }, [selectedData]);

  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update User"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="loginId"
            labelName="Login Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        {/* <div className="column is-one-third">
          <EditTextField
            identifier="password"
            labelName="Password"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div> */}
        {/* /.column */}

        <div className="column is-one-third">
          <EditTextField
            identifier="name"
            labelName="Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}

        
        <div className="column is-one-third">
          <EditTextField
            identifier="contactNumber"
            labelName="Contact Number"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="email"
            labelName="Email"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            required={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <SelectContainer
            identifier="role"
            labelName="Role"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={roleOptions}
          />
        </div>
        {/* /.column */}
       </div>
     </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
