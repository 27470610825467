import axios from "axios";
// import config from "config.json";
import { updateAccessToken } from "features/authentication/authService";
import { getToken } from "features/authentication/authService";
import { saveRefreshToken } from "features/authentication/authService";
import { saveToken } from "features/authentication/authService";
import { globalConfig } from "core/configuration/config";
// axios.defaults.headers.common["Authorization"] = getToken();
// axios.defaults.withCredentials = true;

const securedInstance = axios.create({
  timeout: 30000,
});

securedInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // console.log(error);
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const response = await updateAccessToken();
      const { status, data } = response;
      if (status === 200 || status === 201 || status === 202) {
        saveToken(data.accessToken);
        originalRequest.headers["Authorization"] = "Bearer " + data.accessToken;
        saveRefreshToken(data.refreshToken);
        return securedInstance(originalRequest);
      }
    }
    // console.log("Still going with reject");
    // return Error object with Promise
    return Promise.reject(error);
  }
);
// securedInstance.interceptors.response.use(null, (error) => {
//   //   console.log(error);
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;

//   if (!expectedError) {
//     console.log("Logging the error ", error);
//     toast.error("An unexpected error occured");
//     if (!error.response) {
//       return Promise.reject(error);
//     }
//   }

//   return Promise.reject(error.response);
// });

securedInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const openInstance = axios.create({
  timeout: 30000,
  withCredentials: false,
});

const securedBlobInstance = axios.create({
  timeout: 60000,
  responseType: "blob",
});

securedBlobInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = getToken();
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

securedBlobInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const response = await updateAccessToken();
      const { status, data } = response;
      if (status === 200 || status === 201 || status === 202) {
        saveToken(data.accessToken);
        // originalRequest.headers.Authorization = "Bearer " + data.accessToken;
        // originalRequest.headers["Content-Type"] = "application/json";
        saveRefreshToken(data.refreshToken);
        return securedBlobInstance(originalRequest);
      }
    }
    // console.log("Still going with reject");
    // return Error object with Promise
    return Promise.reject(error);
  }
);

const securedFileUploadInstance = axios.create({
  timeout: 60000,
  headers: {
    Authorization: getToken(),
  },
});

securedFileUploadInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // console.log(error);
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const response = await updateAccessToken();
      // console.log(response);
      const { status, data } = response;
      if (status === 200 || status === 201 || status === 202) {
        // console.log(data);
        saveToken(data.accessToken);
        originalRequest.headers["Authorization"] = "Bearer " + data.accessToken;
        saveRefreshToken(data.refreshToken);
        return securedFileUploadInstance(originalRequest);
      }
    }
    // console.log("Still going with reject");
    // return Error object with Promise
    return Promise.reject(error);
  }
);

export default {
  get: securedInstance.get,
  post: securedInstance.post,
  put: securedInstance.put,
  delete: securedInstance.delete,
  filePost: securedFileUploadInstance.post,
  filePut: securedFileUploadInstance.put,
  openGet: openInstance.get,
  openPost: openInstance.post,
  openPut: openInstance.put,
  openDelete: openInstance.delete,
  blobPost: securedBlobInstance.post,
  blobGet: securedBlobInstance.get,
};
