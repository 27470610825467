import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { GatewayCrud } from "features/gatewayCrud/gatewayCrud";

export const GatewayAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Gateway"}
          description={"Interface for handling gateways"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Gateway"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            <GatewayCrud />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
