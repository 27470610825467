import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { AlarmHistoryTable } from "./alarmHistory__table";
import { AlarmHistorySearch } from "./alarmHistory__search";

export const AlarmHistoryCrud = () => {
  const [alarms, setAlarms] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setAlarms(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

//    // Delete Action
//    const handleDelete = async (data) => {
//     // console.log(data);
//     try {
//       setAction("DELETE");
//       setSelectedId(data["id"]);
//       setSelectedInput(data);
//       const { data: gateway } = await deleteGateway(data["id"]);
//       toast.success("Gateway deleted successfully");
//       const newList = [...alarms];
//       _.remove(newList, function (n) {
//         // console.log("Checking " + data["id"] + " " + n["id"]);
//         return n["id"] == data["id"];
//       });
//       // console.log(newList);
//       setAlarms(newList);
//     } catch (error) {
//       console.log(error);
//       toast.error("Error deleting gateway");
//     }
//   };
  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(alarms, { id: selectedId });
      const updatedDataList = [...alarms];
      updatedDataList[index] = updatedData;
      setAlarms(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...alarms];
      newList.push(updatedData);
      setAlarms(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

 

  return (
    <React.Fragment>
      <AlarmHistorySearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <AlarmHistoryTable
        data={alarms}
        onEdit={handleEdit}
        // onDelete={handleDelete}
      />
      {/* <GatewayForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
      */}
    </React.Fragment>
  );
};
