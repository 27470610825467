import { FormCard } from "core/cards/formCard";
import { MultiCheckboxContainer } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { formatArrayToOptions } from "core/utility/util";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchAllPermissions } from "./featureCrudService";
import { assignPermissions } from "./featureCrudService";

export const PermissionForm = ({ open, onClose, selectedData = {} }) => {
  const { id, associatedPermissions } = selectedData;
  // console.log(selectedData);
  // console.log(associatedPermissions);
  const schema = Joi.object({ permissions: Joi.array().items(Joi.string()) });
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const [permissionOptions, setPermissionOptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // const user = getUser();
      // console.log(user);
      const { data: permissions } = await fetchAllPermissions();
      // console.log(frontendPermissions);
      const sortedPermissions = _.sortBy(permissions, ["name"]);
      const permissionNames = _.uniq(_.map(sortedPermissions, "name"));

      const permissionOptions = formatArrayToOptions(permissionNames);
      setPermissionOptions(permissionOptions);
    };
    fetchData();
  }, []);
  const submitCallback = async (event) => {
    try {
      startLoading();
      // console.log(inputs);
      const permissionsBody = [];
      _.forEach(inputs["permissions"], function (permission) {
        permissionsBody.push(permission);
      });
      const body = {
        permissions: inputs["permissions"],
      };
      // console.log(body);
      const { data: permissions } = await assignPermissions(id, body);
      toast.success("Permissions updated successfully.");
      stopLoading();
      onClose();
      //   onUpdateSuccess(updatedDisposalData);
    } catch (error) {
      console.log(error);
      toast.error(
        "Error updating permission. Please refer console for detailed error"
      );
      stopLoading();
      onClose();
    }
  };
  const defaultInputs = {};
  useEffect(() => {
    if (associatedPermissions) {
      handleInputChange("permissions", associatedPermissions);
    }
  }, [associatedPermissions]);
  const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
    useSubmitForm(schema,  submitCallback, defaultInputs);
  // console.log(inputs);
  const WrappingContainer = ({ children }) => (
    <div className="column is-one-third">{children}</div>
  );

  const FormHtml = (
    <FormCard
      formName={"Permission Form"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={true}
    >
      <div className="columns is-multiline">
        <MultiCheckboxContainer
          identifier={"permissions"}
          labelName={"Permissions"}
          handleInputChange={handleInputChange}
          inputs={inputs}
          errors={errors}
          editAccess={true}
          options={permissionOptions}
          wrapper={WrappingContainer}
        />
      </div>
      {/* /.columns */}
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
