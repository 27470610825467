import { Modal } from "core/components/modal";
import { TabContainer } from "core/components/tab";
import { Panel } from "core/components/tab";
import React from "react";
import { ParameterArrayForm } from "./node__parameterForm";

export const NodeParameter = ({ open, onClose, selectedData }) => {
  if (!selectedData) {
    return "";
  }


  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div className="card">
      
         
              <ParameterArrayForm
                selectedData={selectedData}
                onClose={onClose}
              />
         
      </div>
    </Modal>
  );
};
