import { FormCard } from "core/cards/formCard";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import { useMasterListState } from "features/masterList/masterListProvider";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { addNode } from "./nodeCrudService";
import { editNode } from "./nodeCrudService";
import { useAxiosErrorHandlingHook } from "features/errorDisplay/axiosErrorHandlingHook";

export const NodeForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {
  const { showError } = useAxiosErrorHandlingHook();
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const { companyList } = useMasterListState();

  // Site Options
  const sortedCompanyList = _.sortBy(companyList, ["name"]);
  const companyOptions = _.map(sortedCompanyList, (company) => {
    const { id, name, plotNo } = company;
    return { label: `${name} - ${plotNo}`, value: id };
  });
  const companyNameOptions = addDefaultOption(companyOptions);

  const schema = Joi.object({
    id: Joi.number().label("Id").positive().integer().allow(""),
    deviceId: Joi.string().trim().label("Device Id").required(),
    slaveId: Joi.number().label("Slave Id").positive().required(),
    companyId: Joi.number().label("Company Id").positive().required(),
    encryptionKey: Joi.string().trim().label("Encryption Key").required(),
    make: Joi.string().trim().label("Make").required(),
    model: Joi.string().trim().label("Model").required(),
    tagName: Joi.string().trim().label("Tag Name").required(),
    serialNumber: Joi.string().trim().label("Serial Number").required(),
  });
  const accessToEdit = true;
  const submitCallback = async (event) => {
    const {
      id,
      deviceId,
      slaveId,
      companyId,
      encryptionKey,
      make,
      model,
      tagName,
      serialNumber,
    } = inputs;
    try {
      startLoading();

      const body = {
        id,
        deviceId,
        slaveId,
        companyId,
        encryptionKey,
        make,
        model,
        tagName,
        serialNumber,
      };

      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: node } = await addNode(body);
        // console.log(node);
        onUpdateSuccess(node);
        toast.success("Node Added Successfully");
      } else {
        const { data: node } = await editNode(id, body);
        // console.log(node);

        onUpdateSuccess(node);
        toast.success("Node Edited Successfully");
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      const toastMessage = "Error adding / editing node details";
      showError(error, toastMessage);

      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);
      handleInputChange("deviceId", selectedData["deviceId"]);
      handleInputChange("slaveId", selectedData["slaveId"]);
      handleInputChange("companyId", selectedData["companyId"]);
      handleInputChange("encryptionKey", selectedData["encryptionKey"]);
      handleInputChange("make", selectedData["make"]);
      handleInputChange("model", selectedData["model"]);
      handleInputChange("tagName", selectedData["tagName"]);
      handleInputChange("serialNumber", selectedData["serialNumber"]);
    } else {
      resetInput();
      handleInputChange("id", "");
    }
  }, [selectedData]);

  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update Node   "}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="deviceId"
            labelName="Device Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="slaveId"
            labelName="Slave Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}

        <div className="column is-one-third">
          <SelectContainer
            identifier="companyId"
            labelName="Company Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={companyNameOptions}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="encryptionKey"
            labelName="Encryption Key"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="make"
            labelName="Make"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="model"
            labelName="Model"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="tagName"
            labelName="Tag Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="serialNumber"
            labelName="Serial Number"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
      </div>
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
