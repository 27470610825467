import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
 
import { deleteNode } from "./nodeCrudService";
import { NodeSearch } from "./node_search";
import { NodeTable } from "./node_table";
import { NodeForm } from "./node_form";
import { NodeZoneForm } from "./node__zoneForm";
 import { NodeGatewayForm } from "./node_gatewayForm";
import { NodeParameter } from "./node__parameter";
 
export const NodeCrud = () => {  
  const [Nodes, setNodes] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setNodes(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

  // Delete Action
  const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: Node } = await deleteNode(data["id"]);
      toast.success("Node deleted successfully");
      const newList = [...Nodes];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setNodes(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting node");
    }
  };


  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(Nodes, { id: selectedId });
      const updatedDataList = [...Nodes];
      updatedDataList[index] = updatedData;
      setNodes(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...Nodes];
      newList.push(updatedData);
      setNodes(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

  //zone modal
  const [formZoneModal, openFormZoneModal, closeFormZoneModal] =
  useModal(false);
   const handleZoneAssign = (data) => {
  setAction("ASSIGN");
  setSelectedId(data["id"]);
  setSelectedInput(data);
  openFormZoneModal();
};

 
 

 //gateway modal
 const [formGatewayModal, openFormGatewayModal,closeFormGatewayModel] =
 useModal(false);
  const handleGatewayAssign = (data) => {
 setAction("ASSIGN");
 setSelectedId(data["id"]);
 setSelectedInput(data);
 openFormGatewayModal();
};

// Parameter Action
const [formParameterModal, openFormParameterModal, closeFormParameterModal] =
useModal(false);
const handleParameterEdit = (data) => {
setAction("PARAMETER_EDIT");
setSelectedId(data["id"]);
setSelectedInput(data);
openFormParameterModal();
};


  return (
    <React.Fragment>
      <NodeSearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <NodeTable
        data={Nodes}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onZoneEdit={handleZoneAssign}
        onParameterEdit={handleParameterEdit}
        onGatewayEdit={handleGatewayAssign}



      />
      <NodeForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
      <NodeZoneForm
        open={formZoneModal}
        onClose={closeFormZoneModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
      <NodeGatewayForm
        open={formGatewayModal}
        onClose={closeFormGatewayModel}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
       
      <NodeParameter
        open={formParameterModal}
        onClose={closeFormParameterModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
