import React from "react";
import { FormCard } from "core/cards/formCard";
import { toast } from "react-toastify";
import { FormCardButton } from "core/cards/formCard";

export const SearchAndAddCard = (props) => {
  const { children, onAddNew, onFindAll, options = {} } = props;

  const additionalButtonList = [];
  const addNewAction = () => {
    onAddNew();
  };
  const addNewButton = (
    <FormCardButton
      key="addNewButton"
      text="Add New"
      type="info"
      action={addNewAction}
    ></FormCardButton>
  );
  additionalButtonList.push(addNewButton);
  const findAllAction = () => {
    onFindAll();
  };
  const findAllButton = (
    <FormCardButton
      key="findAllButton"
      text="Find All"
      type="info"
      action={findAllAction}
    ></FormCardButton>
  );
  additionalButtonList.push(findAllButton);

  const updatedOptions = { ...options, buttons: additionalButtonList };
  return (
    <FormCard options={updatedOptions} {...props}>
      {children}
    </FormCard>
  );
};
