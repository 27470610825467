import { FormCard } from "core/cards/formCard";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import { useMasterListState } from "features/masterList/masterListProvider";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { TextAreaField } from "core/components/inputWithAccessControl";
import { saveAssignZone } from "./nodeCrudService";
import { deleteAssignZone } from "./nodeCrudService";
import { getAssignZone } from "./nodeCrudService";

export const NodeZoneForm = ({ open, onClose, selectedData, onUpdateSuccess }) => {
  const { zoneList } = useMasterListState();
  const [associatedZone, setAssociatedZone] = useState();

  // Zone Options
  const zoneNames = _.map(zoneList, "name");
  const zoneNameOptions = addDefaultOption(
    formatArrayToOptions(zoneNames)
  );
  const handleZoneChange = (key, value) => {
    handleInputChange(key, value);
    const obj = _.find(zoneList, { name: value });

    handleInputChange("zoneId", obj["id"]);
  };


  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    id: Joi.number().label("Id").optional(),
    zoneId: Joi.number().label("Zone Id"),
    name: Joi.string().trim().label("Zone Name").required(),

  });
  const accessToEdit = true;

  //submit action
  const submitCallback = async (event) => {
    const {
      id,
      zoneId,

    } = inputs;

    // console.log(inputs);
    if (_.isEmpty(associatedZone["name"])) {

      try {
        startLoading();

        const body = {
          "nodeId": id,
          "zoneId": zoneId,
        };


        // console.log(body);
        const { data: node } = await saveAssignZone(body);
        onUpdateSuccess(node);
        // console.log(node);

        toast.success("Node assign to zone successfully");
        stopLoading();
      } catch (error) {
        console.log(error);
        toast.error("Error while assigning zone");
        stopLoading();
      }
    } else {
      toast.error("Error while assigning zone");

    }
    onClose();
  };

  //fetching assign zone
  useEffect(() => {
    const fetchAssignZones = async () => {
      //  console.log(selectedData);
      try {
        const { data: zone } = await getAssignZone(selectedData["id"])
        const zoneId = zone

        setAssociatedZone(zoneId);
        // console.log(associatedZone);
      } catch (error) {
        console.log(error);
      }
    }
    if (selectedData != undefined) {
      fetchAssignZones();
    }
  }, [selectedData])

  //unassign zone action
  const handleUnassignZone = async (event) => {
    const { id, } = inputs;

    // console.log(inputs);
    if (!_.isEmpty(associatedZone["name"])) {
      try {
        startLoading();

        const body = {
          "nodeId": id,

        };
        const { data: node } = await deleteAssignZone(body);
        // console.log(node);

        toast.success("Removed assign zone successfully");
        stopLoading();
        // Reset input values

        setAssociatedZone({});
      } catch (error) {
        console.log(error);
        toast.error("Error removing zone");
        stopLoading();
      }
    } else {
      toast.error("Zone not found");

    }
    onClose();
  };

  let defaultInputs = { id: 0 };

  useEffect(() => {
    if (!_.isEmpty(selectedData)) {
      // console.log(selectedData);
      handleInputChange("id", selectedData["id"]);

      // console.log(selectedData["id"]);

    } else {
      resetInput();
      handleInputChange("id", 0);
    }
  }, [selectedData]);

  useEffect(() => {
    if (!_.isEmpty(associatedZone)) {
      // console.log(associatedZone);
      handleInputChange("name", associatedZone["name"]);
      handleInputChange("id", selectedData["id"]);
    } else {
      resetInput();
      // handleInputChange("name", " ");
    } if (selectedData != undefined) {
      handleInputChange("id", selectedData["id"]);
    }
  }, [associatedZone, selectedData]);



  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);
  const FormHtml = (

    <FormCard
      formName={"Assign Zone"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            required={false}
          />
        </div>

        <div className="column is-one-third">
          <SelectContainer
            identifier="name"
            labelName="Zone Name"
            handleInputChange={handleZoneChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={zoneNameOptions}
          />
        </div>
        <div className="column is-full">
          <button
            type="button"
            className="m-2 button is-small is-danger"
            onClick={() => handleUnassignZone()}
          >
            Remove Zone
          </button>
        </div>

      </div>
      {/* /.columns */}
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
