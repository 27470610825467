import { DeleteIcon } from "core/components/svgIcon";
import { NodeIcon } from "core/components/svgIcon";
import { PermissionIcon } from "core/components/svgIcon";
import { EditIcon } from "core/components/svgIcon";
import { FactoryIcon } from "core/components/svgIcon";
import { Table } from "core/components/table";
import { useConfirmBox } from "core/hooks/confirmHook";
import { useModal } from "core/hooks/modalHook";
import _ from "lodash";
import React, { useState } from "react";

export const ParameterTable = ({ data, onEdit, onDelete, onFeatureEdit }) => {
  // Edit
  const handleEditAction = (value) => {
    // console.log(value);
    onEdit(value);
  };

  

  // Delete Action
  const options = { onSuccess: onDelete };
  const { confirmContainer, openConfirmBox, setSelectedInput } =
    useConfirmBox(options);

  const handleDeleteAction = (value) => {
    setSelectedInput(value);
    openConfirmBox();
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  const columnDefs = [
    {
      headerName: "Parameter",
      children: [
        
        {
          headerName: "Id",
          field: "id",
          maxWidth: 150,
        },
        {
          headerName: "Display Name",
          field: "displayName",
          minWidth: 150,
        },
        {
          headerName: "Name",
          field: "name",
          maxWidth: 150,
        },
        {
          headerName: "Details",
          valueGetter: function (params) {
            return params.data;
          },
          cellRenderer: "actionBtnCellRenderer",
          sortable: false,
          filter: false,
          minWidth: 300,
        },
      ],
    },
  ];

  const ActionButtonRenderer = ({ value }) => {
    return (
      <React.Fragment>
        <span
          title="Edit"
          className="mx-2 is-clickable"
          onClick={() => handleEditAction(value)}
        >
          <EditIcon />
        </span>
        <span
          title="Delete"
          className="mx-2 is-clickable"
          onClick={() => handleDeleteAction(value)}
        >
          <DeleteIcon />
        </span>
       
      </React.Fragment>
    );
  };

  const frameworkComponents = {
    actionBtnCellRenderer: ActionButtonRenderer,
  };

  return (
    <React.Fragment>
      <Table
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        paginationAutoPageSize={true}
        pagination={true}
        frameworkComponents={frameworkComponents}
      />
      {confirmContainer}
    </React.Fragment>
  );
};
