import React from "react";
import Joi from "joi";
import { SearchAndAddCard } from "core/cards/searchAndAddCard";
import { useLoading } from "core/hooks/loadingHook";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { useMasterListState } from "features/masterList/masterListProvider";
import { addDefaultOption } from "core/utility/util";
import { findNodesByCompanyId } from "./userCrudService";
import { SearchableSelectContainer } from "core/components/inputWithAccessControl";
import { EditTextWithSearchField } from "core/components/inputWithAccessControl";
import { FormCard } from "core/cards/formCard";


export const NodeSearch = ({ onSearchResults, onAdd }) => {
  const { companyList } = useMasterListState();

  // Site Options
  const sortedCompanyList = _.sortBy(companyList, ["name"]);
  const companyNames = _.map(sortedCompanyList, (company) => {
    const { id, name } = company;
    return name;
  });

  const companyOptions = _.map(sortedCompanyList, (company) => {
    const { id, name } = company;
    return { label: `${name}`, value: _.toString(id) };
  });
  // const option = { label: value, value: value };
  const companyNameOptions = addDefaultOption(companyOptions);

  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const schema = Joi.object({
    name: Joi.string().trim().label("Node Name").allow(""),
  });
  const handleSearch = async (event) => {
    const { name } = inputs;
    try {
      startLoading();
      const body = {
        name: _.isNil(name) ? "" : name,
      };
      // console.log(nodeName);
      // console.log(body);
      const { data: nodes } = await findNodesByCompanyId(body);
      onSearchResults(nodes);
      //   console.log(nodes);
      if (nodes.length > 0) {
        toast.success("Data fetched");
      } else {
        toast.warn("No data found");
      }

      stopLoading();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        toast.warn("No data found");
      } else {
        toast.error("Error fetching node data");
      }
      stopLoading();
    }
  };
  const defaultInputs = {};
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, handleSearch, defaultInputs);

  return (
    <React.Fragment>
      <FormCard
        formName={"Search Node"}
        onSubmit={handleSubmit}
        // submitAccess={true}
        // submitText={"Search"}
      >
        <div className="columns is-multiline">
          <div className="column is-one-half">
            <EditTextWithSearchField
              identifier="name"
              labelName="Company Name"
              handleInputChange={handleInputChange}
              editAccess={true}
              inputs={inputs}
              errors={errors}
              options={companyNameOptions}
              handleSearch={handleSearch}

              />
          </div>
          {/* /.column */}
        </div>
        {/* /.columns */}
      </FormCard>
      {loaderContainer}
    </React.Fragment>
  );
};
