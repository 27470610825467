import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchUser(body) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/users/search`, {
    params: body,
  });
}

export function findAll(body) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/users`, {
    params: body,
  });
}

export function addUser(body) {
  const base = globalConfig.get().apiEndpoint;
  return http.post(`${base}/users`, body);
}

export function editUser(id, body) {
  const base = globalConfig.get().apiEndpoint;
  return http.put(`${base}/users/${id}`, body);
}

export function deleteUser(id) {
  const base = globalConfig.get().apiEndpoint;
  return http.delete(`${base}/users/${id}`);
}

export function fetchAllFeatures() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/features`);
}
export function assignFeatures(id, body) {
  const base = globalConfig.get().apiEndpoint;
  return http.post(`${base}/features/assign`, body);
}

export function getAssignedFeaturesByUserId(userId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/features/assign/${userId}`);
}

export function assignSites(id, body) {
  const base = globalConfig.get().apiEndpoint;
  return http.post(`${base}/sites/assign`, body);
}

export function getAssignedSitesByUserId(userId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/sites/assign/${userId}`);
}

export function findNodesByCompanyId(body) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/search/company/${body.name}`);
}

export function assignNodes(id, body) {
  const base = globalConfig.get().apiEndpoint;
  return http.post(`${base}/nodes/assign/user`, body);
}

export function getAssignedNodesByUserId(userId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/assigned/${userId}`);
}
