import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { deletePermission } from "features/permission/permissionCrudService";
 
import { PermissionSearch } from "./permission_search";
import { PermissionTable } from "./permission_table";
import { PermissionForm } from "./permission_form";

export const PermissionCrud = () => {
  const [permissions, setPermission] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setPermission(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

   // Delete Action
   const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: Permission } = await deletePermission(data["id"]);
      toast.success("Permission deleted successfully");
      const newList = [...permissions];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setPermission(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting permission");
    }
  };
  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(permissions, { id: selectedId });
      const updatedDataList = [...permissions];
      updatedDataList[index] = updatedData;
      setPermission(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...permissions];
      newList.push(updatedData);
      setPermission(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

 

  return (
    <React.Fragment>
      <PermissionSearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <PermissionTable
        data={permissions}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <PermissionForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
