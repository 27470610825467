import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";

export const Table = (props) => {
  const {
    tableStyle,
    rowData,
    columnDefs,
    colDef,
    paginationAutoPageSize = false,
    pagination = false,
    paginationPageSize = 10,
    frameworkComponents = {},
    defaultFilter = {},
    theme = "alpine",
  } = props;

  // To be added if needed a default filter
  // var defaultFilter = {
  //   currentStatus: { type: "startsWith", filter: "INITIAL_ENTRY" },
  // };
  const onRowDataChanged = (event) => {
    const { api: gridApi } = event;
    // console.log(model);
    gridApi.setFilterModel(defaultFilter);
    gridApi.onFilterChanged();
  };

  const defaultTableStyle = { height: "700px", width: "100%" };
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  return (
    <React.Fragment>
      <div
        style={tableStyle || defaultTableStyle}
        className={`ag-theme-${theme}`}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={colDef || defaultColDef}
          // paginationAutoPageSize={paginationAutoPageSize}
          pagination={pagination}
          frameworkComponents={frameworkComponents}
          onRowDataChanged={onRowDataChanged}
          // rowModelType={"infinite"}
          paginationPageSize={paginationPageSize}
        />
      </div>
    </React.Fragment>
  );
};
