import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import React from "react";
import { toast } from "react-toastify";
import { getUser, logout } from "features/authentication/authService";

import { updatePassword } from "features/user/userService";
import { useNavigate } from "react-router";

export const UserProfile = (props) => {
  const navigate = useNavigate();
  const schema = Joi.object({
    password: Joi.string()
      .trim()
      .pattern(new RegExp("^[a-zA-Z0-9]{5,30}$"))
      .required()
      .label("New Password")
      .messages({
        "string.pattern.base": `"Password" should be a alphanumeric combination with 5-30 characters`,
      }),
    repeatPassword: Joi.string().label("Repeat Password").valid(Joi.ref('password')).required(),
  });

  const submitCallback = async (event) => {
    const { password, repeatPassword } = inputs;
    try {
      if (password === repeatPassword) {
        const { data: response } = await updatePassword(password);
        toast.success("Password updated successfully. Logging out user.");
        logout();
        // props.history.push("/");
        navigate('/');
      } else {
        toast.error("Passwords do not match");
      }
    } catch (ex) {
      toast.error("Unable to update password. Please check console");
      console.log(ex);
    }
  };

  const user = getUser();
  // console.log(user);

  const defaultInputs = {};
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  return (
    <div className="container">
      <div className="card mx-2 my-2">
        <header className="card-header">
          <p className="card-header-title">
            <h5 className="is-size-5">User Details</h5>
          </p>
        </header>
        <div className="card-content">
          <div className="content">
            <h6 className="is-size-6">User Name: {user.name}</h6>
            <h6 className="is-size-6">Email: {user.email}</h6>
            <h6 className="is-size-6">Contact : {user.contactNumber}</h6>
          </div>
        </div>
        {/* /.card-content */}
      </div>
      {/* /.card */}
      <FormCard
        formName={"Update Password"}
        onSubmit={handleSubmit}
        onFormReset={resetInput}
        submitAccess={true}
      >
        <div className="columns is-multiline">
          <div className="column is-one-third">
            <EditTextField
              identifier={"password"}
              labelName={"New Password"}
              handleInputChange={handleInputChange}
              type={"password"}
              editAccess={true}
              inputs={inputs}
              errors={errors}
              required={true}
            />
          </div>
          <div className="column is-one-third">
            <EditTextField
              identifier={"repeatPassword"}
              labelName={"Repeat Password"}
              handleInputChange={handleInputChange}
              type={"password"}
              editAccess={true}
              inputs={inputs}
              errors={errors}
              required={true}
            />
          </div>
        </div>
        {/* /.columns */}
      </FormCard>
    </div>
  );
};
