import { FormCard } from "core/cards/formCard";
import { MultiCheckboxContainer } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { formatArrayToOptions } from "core/utility/util";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchAllFeatures } from "./userCrudService";
import { assignFeatures } from "./userCrudService";
import { getAssignedFeaturesByUserId } from "./userCrudService";

export const FeatureForm = ({ open, onClose, selectedData = {} }) => {
  const { id } = selectedData;
  const [associatedFeatures, setAssociatedFeatures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: features } = await getAssignedFeaturesByUserId(id);
        // console.log(features);
        const featureIds = _.map(features, (f) => {
          return _.toString(f["id"]);
        });
        setAssociatedFeatures(featureIds);

        // setFeatures(dummy);
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching feature data");
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  //   console.log(selectedData);
  // console.log(associatedFeatures);
  const schema = Joi.object({
    features: Joi.array().items(Joi.number().positive().integer()),
  });
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const [featureOptions, setFeatureOptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // const user = getUser();
      // console.log(user);
      const { data: features } = await fetchAllFeatures();
      //   console.log(features);
      const sortedFeatures = _.sortedUniqBy(
        _.sortBy(features, ["name"]),
        "name"
      );

      //   const featureNames = _.uniq(_.map(sortedFeatures, "name"));
      //   const featureOptions = formatArrayToOptions(featureNames);
      const featureOptions = [];
      _.forEach(sortedFeatures, (f) => {
        featureOptions.push({ value: _.toString(f.id), label: f.name });
      });
      setFeatureOptions(featureOptions);
    };
    fetchData();
  }, []);
  const submitCallback = async (event) => {
    try {
      startLoading();
      // console.log(inputs);
      const featuresBody = [];
      _.forEach(inputs["features"], function (feature) {
        featuresBody.push({ id: _.toInteger(feature) });
      });
      const body = {
        userId: id,
        features: featuresBody,
      };
      //   console.log(body);
      const { data: features } = await assignFeatures(id, body);
      toast.success("Features updated successfully.");
      stopLoading();
      onClose();
      //   onUpdateSuccess(updatedDisposalData);
    } catch (error) {
      console.log(error);
      toast.error(
        "Error updating feature. Please refer console for detailed error"
      );
      stopLoading();
      onClose();
    }
  };
  const defaultInputs = {};
  useEffect(() => {
    if (associatedFeatures) {
      handleInputChange("features", associatedFeatures);
    }
  }, [associatedFeatures]);
  const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
    useSubmitForm(schema, submitCallback, defaultInputs);
  // console.log(inputs);
  const WrappingContainer = ({ children }) => (
    <div className="column is-one-third">{children}</div>
  );

  const FormHtml = (
    <FormCard
      formName={"Feature Form"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={true}
    >
      <div className="columns is-multiline">
        <MultiCheckboxContainer
          identifier={"features"}
          labelName={"Features"}
          handleInputChange={handleInputChange}
          inputs={inputs}
          errors={errors}
          editAccess={true}
          options={featureOptions}
          wrapper={WrappingContainer}
        />
      </div>
      {/* /.columns */}
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
