import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchFeature(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/features/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/features`, {
      params: body,
    });
  }

  export function addFeature(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/features`, body);
  }
  
  export function editFeature(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/features/${id}`, body);
  }

  export function deleteFeature(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/features/delete/${id}`);
  }
  
  export function fetchAllFeatures() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/features`);
  }

  export function fetchAllPermissions(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/permissions`, {
      params: body,
    });
  }
  
  export function assignPermissions(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/features/${id}/permissions`, body);
  }
