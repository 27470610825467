import { DeleteIcon } from "core/components/svgIcon";
import { NodeIcon } from "core/components/svgIcon";
import { PermissionIcon } from "core/components/svgIcon";
import { EditIcon } from "core/components/svgIcon";
import { FactoryIcon } from "core/components/svgIcon";
import { Table } from "core/components/table";
import { useConfirmBox } from "core/hooks/confirmHook";
import { useModal } from "core/hooks/modalHook";
import _ from "lodash";
import React, { useState } from "react";

export const AlarmHistoryTable = ({ data, onFeatureEdit }) => {


  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  const columnDefs = [
    {
      headerName: "Alarm History",
      children: [
        
        {
          headerName: "Id",
          field: "id",
          maxWidth: 100,
        },
        {
          headerName: "Node Id",
          field: "nodeId",
          maxWidth: 150,
        },
        {
          headerName: "Created On",
          field: "createdOn",
          maxWidth: 200,
        },{
            headerName: "Reason",
            field: "reason",
            maxWidth: 450,
          },{
            headerName: "Type",
            field: "type",
            maxWidth: 210,
          },
       
      ],
    },
  ];

//   const ActionButtonRenderer = ({ value }) => {
//     return (
//       <React.Fragment>
//         <span
//           title="Edit"
//           className="mx-2 is-clickable"
//           onClick={() => handleEditAction(value)}
//         >
//           <EditIcon />
//         </span>
//         <span
//           title="Delete"
//           className="mx-2 is-clickable"
//           onClick={() => handleDeleteAction(value)}
//         >
//           <DeleteIcon />
//         </span>
       
//       </React.Fragment>
//     );
//   };

//   const frameworkComponents = {
//     actionBtnCellRenderer: ActionButtonRenderer,
//   };

  return (
    <React.Fragment>
      <Table
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        paginationAutoPageSize={true}
        pagination={true}
        // frameworkComponents={frameworkComponents}
      />
      {/* {confirmContainer} */}
    </React.Fragment>
  );
};
