import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchCompany(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/companies/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/companies`, {
      params: body,
    });
  }
  export function addCompany(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/companies`, body);
  }
  
  export function editcompany(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/companies/${id}`, body);
  }

  export function deletecompany(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/companies/delete/${id}`);
  }
  
  export function fetchAllCompany() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/companies`);
  }

