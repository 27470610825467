import React from "react";
import PropTypes from "prop-types";

export const FormCardButton = ({ text, type = "success", action }) => {
  const classNameType = {
    success: "is-success",
    error: "is-danger",
    info: "is-info",
  };

  const className = classNameType[type];
  return (
    <p className="control">
      <button
        type="button"
        className={`button is-small ${className}`}
        onClick={action}
      >
        {text}
      </button>
    </p>
  );
};

export const FormCard = (props) => {
  const {
    formName,
    onSubmit,
    children,
    onFormReset,
    onReject,
    options = {},
    submitAccess = false,
    submitText = "Submit",

  } = props;

  let additionalButtonHtml = "";
  const { buttons } = options;
  if (buttons) {
    additionalButtonHtml = buttons;
  }

  let buttonHtml = "";
  let footerButtonHtml = "";
  let returnButtonHtml = "";
  if (onReject) {
    // returnButtonHtml = (
    //   <p className="control">
    //     <button
    //       type="button"
    //       className="button is-small is-danger"
    //       onClick={onReject}
    //     >
    //       Return
    //     </button>
    //   </p>
    // );

    returnButtonHtml = (
      <FormCardButton text={"Return"} action={onReject} type={"error"} />
    );
  }

  if (submitAccess) {
    buttonHtml = (
      <span className="card-header-icon">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {/* <p className="control">
            <button
              type="button"
              className="button is-small"
              onClick={onFormReset}
            >
              Clear
            </button>
          </p> */}
          <p className="control">
            <button type="submit" className="button is-success is-small">
              {submitText}
            </button>
          </p>
        </div>
      </span>
    );
    footerButtonHtml = (
      <div className="card-footer-item">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {/* <p className="control">
            <button
              type="button"
              className="button is-small"
              onClick={onFormReset}
            >
              Clear
            </button>
          </p> */}
          <p className="control">
            <button type="submit" className="button is-success is-small">
            {submitText}
            </button>
          </p>
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="card card-full-height">
        <form onSubmit={onSubmit}>
          <header className="card-header">
            <p className="card-header-title is-size-6">{formName}</p>
            {buttonHtml}
          </header>
          {/* /.card-header */}
          <div className="card-content">{children}</div>
          <footer className="card-footer">{footerButtonHtml}</footer>
          {/* /.card-content */}
        </form>
      </div>
    </React.Fragment>
  );
};

FormCard.propTypes = {
  formName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormReset: PropTypes.func,
  submitAccess: PropTypes.bool,
};
