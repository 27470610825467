import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchParameter(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/parameters/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/parameters`, {
      params: body,
    });
  }

  export function addParameter(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/parameters`, body);
  }
  
  export function editParameter(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/parameters/${id}`, body);
  }

  export function deleteParameter(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/parameters/delete/${id}`);
  }
  
  export function fetchAllParameter() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/parameters`);
  }

