import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { AlarmHistoryCrud } from "features/alarmHistoryCrud/alarmHistoryCrud";

export const AlarmAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Alarm History"}
          description={"Interface for handling alarm history"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Alarm History"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            <AlarmHistoryCrud />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
