import React from "react";
import { FormCard } from "core/cards/formCard";
import Joi from "joi";
import { useLoading } from "core/hooks/loadingHook";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { DateField } from "core/components/inputWithAccessControl";
import { useMasterListState } from "features/masterList/masterListProvider";
import { createCorrectedData } from "./dataGenerationService";
import { SearchableSelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { TextAreaField } from "core/components/inputWithAccessControl";


export const DataGenerationForm = () => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();

    const { nodeList, companyList } = useMasterListState();

    // Create an array of options with device ID as value and corresponding company name as label
    const deviceOptions = nodeList.map(node => {
        const { id, companyId, tagName } = node;
        const companyName = companyList.find(company => company.id === companyId)?.name;

        return {
            value: id,
            label: companyName ? `${companyName} - ${tagName}` : id
        };
    });

    const schema = Joi.object({
        date: Joi.date().label("Date").required(),
        nodeId: Joi.number().label("Node Id").required(),
        reason: Joi.string().trim().label("Reason").min(10).required(),

    });
    const submitCallback = async (event) => {
        const { date, nodeId, reason } = inputs;
        //   console.log(inputs);

        const body = {
            "date": date,
            "nodeId": nodeId,
            "reason": reason,


        };
        try {
            startLoading();
            const { data: data } = await createCorrectedData(body);
            //   console.log(data);
            toast.success("Data generated successfully");


            stopLoading();
        } catch (error) {
            console.log(error);

            toast.error(
                "Error while generating corrected data"
            );

            stopLoading();
        }
    };
    const defaultInputs = {};
    const {
        inputs,
        errors,
        handleInputChange,
        handleSubmit,
        resetInput,
        additionalValidation,
    } = useSubmitForm(schema, submitCallback, defaultInputs);

    const accessToEdit = true;




    return (
        <React.Fragment>
            <FormCard
                formName={"Data Generation"}
                onSubmit={handleSubmit}
                onFormReset={resetInput}
                submitAccess={true}
                submitText={"Submit"}
            >
                <div className="columns is-multiline">
                    <div className="column is-one-third">
                        <DateField
                            identifier="date"
                            labelName="Date"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}


                        />
                    </div>
                    {/* /.column */}


                    <div className="column is-one-third">
                        <SearchableSelectContainer
                            identifier="nodeId"
                            labelName="Node Id"
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            options={deviceOptions}
                        ></SearchableSelectContainer>
                    </div>
                    <div className="column is-half">
                        <TextAreaField
                            identifier="reason"
                            labelName="Reason"
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            rows={2}
                            editAccess={true}
                        />
                    </div>

                </div>
                {/* /.columns */}
            </FormCard>
            {loaderContainer}
        </React.Fragment>
    );
};
