import React, { useEffect, useState } from "react";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import _ from "lodash";
import { addDefaultOption } from "core/utility/util";
import { formatArrayToOptions } from "core/utility/util";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { useMasterListState } from "features/masterList/masterListProvider";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { saveNodeParameters } from "./nodeCrudService";
import { getAssignParameters } from "./nodeCrudService";

export const ParameterArrayForm = ({ selectedData, onClose }) => {
  const { id, parameters } = selectedData;
  const [associatedParameters, setAssociatedParameters] = useState([]);

  // console.log(selectedData);
  const accessToEdit = true;

  const saveParameterData = async (result) => {
    try {
      startLoading();

      const body = { "id": JSON.stringify(id), parameters: result };

      // console.log(body);

      const { data: parameter } = await saveNodeParameters(id, body);
      // console.log(parameter);
      toast.success("Parameter updated successfully");

      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error("Error adding / editing parameter details");
      stopLoading();
    }
    onClose();
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    // console.log(parameterList);
    const result = schema.validate(parameterList, { abortEarly: false });
    if (result.error) {
      console.log(result);
      toast.error("Validation Error");
    } else {
      console.log(result);
      saveParameterData(result.value);
    }
  };

  const objectSchema = Joi.object({
    id: Joi.number().label("Id").positive().integer().allow(0),
    displayName: Joi.string().trim().label("Display Name").required(),
    name: Joi.string().trim().label("Name").required(),
    lowerLimit: Joi.number().label("Lower Limit").required(),
    upperLimit: Joi.number().label("Upper Limit").required(),
    unit: Joi.string().trim().label("Unit").allow(''),
  });
  const schema = Joi.array().items(objectSchema).min(1).unique().required();
  const [parameterList, setParameterList] = useState([]);
  useEffect(() => {
    if (parameters) {
      setParameterList(parameters);

    }
  }, [parameters]);
  const handleAddRow = () => {
    // console.log("Add row clicked");
    const newList = [...parameterList];
    newList.push({});
    setParameterList(newList);
  };
  const handleEditRow = (arrayIndex, name, value) => {
    // console.log(`Row edited ${arrayIndex} ${name} ${value}`);
    const updatedData = parameterList[arrayIndex];
    updatedData[name] = value;
    const updatedDataList = [...parameterList];
    updatedDataList[arrayIndex] = updatedData;
    setParameterList(updatedDataList);
  };
  const handleDeleteRow = async (arrayIndex) => {
    // console.log(`Delete row clicked ${arrayIndex}`);
    const newList = [...parameterList];
    newList.splice(arrayIndex, 1);
    // console.log(newList);
    setParameterList(newList);
    try {
      const parameterIdToDelete = associatedParameters[arrayIndex].nodeId;
      // console.log(parameterIdToDelete)

      const updatedList = [...parameterList];
      updatedList.splice(arrayIndex, 1);
      setParameterList(updatedList);

      const updatedAssociatedParameters = [...associatedParameters];
      updatedAssociatedParameters.splice(arrayIndex, 1);
      setAssociatedParameters(updatedAssociatedParameters);

      const body = { "id": JSON.stringify(parameterIdToDelete), parameters: updatedAssociatedParameters };

      // Call saveNodeParameters method to update the parameter list in the database
      const { data: parameter } = await saveNodeParameters(id, body);
      // console.log(parameter);
      toast.success("Parameter Deleted Successfully");
    } catch (error) {
      console.log(error);
      // toast.error("Error deleting parameter");
    }
  };

  //fetching assign parameters
  useEffect(() => {
    const fetchAssignNodeParameter = async () => {
      // console.log(selectedData);
      try {
        const { data: parameters } = await getAssignParameters(selectedData["id"])
        //  console.log(parameters)
        const parameterData = parameters || [];
        setAssociatedParameters(parameterData);
        const updatedParameterList = parameterData.map(param => ({
          id: param.parameterId,
          lowerLimit: param.lowerLimit,
          upperLimit: param.upperLimit,
          unit: param.unit,
          displayName: param.displayName,
          name: param.name
        }));
        setParameterList(updatedParameterList);
        //  setParameterList(parameterData)     
        //  console.log(associatedParameters);

      } catch (error) {
        console.log(error);
      }
    }
    if (selectedData != undefined) {
      fetchAssignNodeParameter();

    }

  }, [selectedData])

  const options = {
    buttons: (
      <button
        type="button"
        className="button is-info is-small mx-2"
        onClick={handleAddRow}
      >
        Add Parameter Row
      </button>
    ),
  };
  let parameterRowList = [];
  _.forEach(parameterList, (p, index) => {
    // console.log(p);
    parameterRowList.push(
      <ParameterRow
        key={index}
        arrayIndex={index}
        schema={objectSchema}
        parameter={p}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
      />
    );
  });

  const { loaderContainer, startLoading, stopLoading } = useLoading();
  return (
    <React.Fragment>
      <FormCard
        formName={"Add/Update Parameter"}
        onSubmit={handleSubmit}
        submitAccess={accessToEdit}
        options={options}
      >
        {parameterRowList}
      </FormCard>
      {loaderContainer}
    </React.Fragment>
  );
};

const ParameterRow = ({
  arrayIndex,
  schema,
  parameter,
  onRowEdit,
  onRowDelete,
}) => {
  //   const [parameterList, setParameterList] = useState([]);
  const { parameterList } = useMasterListState();


  const parameterNames = _.map(parameterList, "displayName");
  const parameterOptions = addDefaultOption(
    formatArrayToOptions(parameterNames)
  );
  const submitCallback = () => { };
  let defaultInputs = {};
  useEffect(() => {
    if (parameter) {
      const {

        id,
        name,
        displayName,
        lowerLimit,
        upperLimit,
        unit,
      } = parameter;
      handleInputChange("id", id);
      handleInputChange("name", name);
      handleInputChange("displayName", displayName);
      handleInputChange("lowerLimit", lowerLimit);
      handleInputChange("upperLimit", upperLimit);
      handleInputChange("unit", unit);
    }
  }, [parameter]);

  const { inputs, errors, handleInputChange } = useSubmitForm(
    schema,
    submitCallback,
    defaultInputs
  );

  // Custom input change
  const handleCustomInputChange = (name, value) => {
    onRowEdit(arrayIndex, name, value);
    handleInputChange(name, value);
  };

  // Parameter option change
  const handleParameterNameChange = (displayName, value) => {
    const selectedParameter = _.find(parameterList, { displayName: value });
    // console.log(selectedParameter);
    const {
      id,
      name,
      lowerLimit,
      upperLimit,
      unit,
    } = selectedParameter;
    handleCustomInputChange("id", id);
    handleCustomInputChange("name", name);
    handleCustomInputChange("lowerLimit", lowerLimit);
    handleCustomInputChange("upperLimit", upperLimit);
    handleCustomInputChange("unit", unit);
    handleCustomInputChange(displayName, value);
  };
  return (
    <div className="box">
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <SelectContainer
            identifier="displayName"
            labelName="Display Name"
            handleInputChange={handleParameterNameChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={parameterOptions}
          />
        </div>
        {/* /.column */}

        <div className="column is-one-third">
          <EditTextField
            identifier="lowerLimit"
            labelName="Lower Limit"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <EditTextField
            identifier="upperLimit"
            labelName="Upper Limit"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}

        <div className="column is-one-third">
          <EditTextField
            identifier="unit"
            labelName="Unit"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}

        <div className="column is-full">
          <button
            type="button"
            className="m-2 button is-small is-danger"
            onClick={() => onRowDelete(arrayIndex)}
          >
            Delete Row
          </button>
        </div>
        {/* /.column */}
      </div>
      {/* /.columns */}
    </div>
  );
};
