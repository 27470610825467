import { FormCard } from "core/cards/formCard";
import { MultiCheckboxContainer } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { assignNodes } from "./userCrudService";
import { getAssignedNodesByUserId } from "./userCrudService";
import { NodeSearch } from "./user__node__search";

export const NodeForm = ({ open, onClose, selectedData = {} }) => {
  const { id } = selectedData;
  const [associatedNodes, setAssociatedNodes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: nodes } = await getAssignedNodesByUserId(id);
        // console.log(nodes);
        const nodeIds = _.map(nodes, (n) => {
          return _.toString(n["id"]);
        });
        setAssociatedNodes(nodeIds);

        // setNodes(dummy);
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching node data");
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  //   console.log(selectedData);
  // console.log(associatedNodes);

  const schema = Joi.object({
    site: Joi.number().integer().positive().optional(),
    nodes: Joi.array().items(Joi.number().positive().integer()),
  });
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const [nodeOptions, setNodeOptions] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const { data: nodes } = await fetchAllNodes();
  //     console.log(nodes);
  //     const sortedNodes = _.sortedUniqBy(_.sortBy(nodes, ["name"]), "name");

  //     //   const nodeNames = _.uniq(_.map(sortedNodes, "name"));
  //     //   const nodeOptions = formatArrayToOptions(nodeNames);
  //     const nodeOptions = [];
  //     _.forEach(sortedNodes, (f) => {
  //       nodeOptions.push({ value: _.toString(f.id), label: f.name });
  //     });
  //     setNodeOptions(nodeOptions);
  //   };
  //   fetchData();
  // }, []);

  const handleSearchResults = (nodes) => {
    // console.log(nodes);
    const sortedNodes = _.sortedUniqBy(_.sortBy(nodes, ["id"]), "id");
    const nodeOptions = [];
    _.forEach(sortedNodes, (f) => {
      nodeOptions.push({
        value: _.toString(f.id),
        label: `${f.deviceId} - ${f.tagName}`,
      });
    });
    setNodeOptions(nodeOptions);
  };

  const submitCallback = async (event) => {
    try {
      startLoading();
      // console.log(inputs);
      const nodesBody = [];
      _.forEach(inputs["nodes"], function (node) {
        nodesBody.push({ id: _.toInteger(node) });
      });
      const body = {
        userId: id,
        nodes: nodesBody,
      };
      //   console.log(body);
      const { data: nodes } = await assignNodes(id, body);
      toast.success("Nodes updated successfully.");
      stopLoading();
      onClose();
      //   onUpdateSuccess(updatedDisposalData);
    } catch (error) {
      console.log(error);
      toast.error(
        "Error updating node. Please refer console for detailed error"
      );
      stopLoading();
      onClose();
    }
  };
  const defaultInputs = {};
  useEffect(() => {
    if (associatedNodes) {
      handleInputChange("nodes", associatedNodes);
    }
  }, [associatedNodes]);
  const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
    useSubmitForm(schema, submitCallback, defaultInputs);
  // console.log(inputs);
  const WrappingContainer = ({ children }) => (
    <div className="column is-one-third">{children}</div>
  );

  const FormHtml = (
    <FormCard
      formName={"Node Form"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={true}
    >
      <div className="columns is-multiline">
        <MultiCheckboxContainer
          identifier={"nodes"}
          labelName={"Nodes"}
          handleInputChange={handleInputChange}
          inputs={inputs}
          errors={errors}
          editAccess={true}
          options={nodeOptions}
          wrapper={WrappingContainer}
        />
      </div>
      {/* /.columns */}
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>
        <NodeSearch onSearchResults={handleSearchResults} />
      </div>
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
