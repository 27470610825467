import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { CompanyForm } from "./company_form";
import { CompanySearch } from "./compnay_search";
import { CompanyTable } from "./company_table";
import { deletecompany } from "./companyCrudService";

export const CompanyCrud = () => {
  const [Companies, setCompanies] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setCompanies(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

  // Delete Action
  const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: Company } = await deletecompany(data["id"]);
      toast.success("Company deleted successfully");
      const newList = [...Companies];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setCompanies(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting company");
    }
  };


  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(Companies, { id: selectedId });
      const updatedDataList = [...Companies];
      updatedDataList[index] = updatedData;
      setCompanies(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...Companies];
      newList.push(updatedData);
      setCompanies(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

 

  return (
    <React.Fragment>
      <CompanySearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <CompanyTable
        data={Companies}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <CompanyForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
