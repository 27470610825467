import React, { useEffect, useState } from "react";

import _ from "lodash";
import dummy from "./dummyData.json";
import { getAllGateway, getAllUsers } from "./masterListService";
import { getAllZones } from "./masterListService";
import { getAllParameters } from "./masterListService";
import { getAllCompanies } from "./masterListService";
import { getAllNodes } from "./masterListService";
import { fetchOnlineCompanyCount } from "./masterListService";
const MasterlistStateContext = React.createContext();

function MasterlistProvider({ children }) {
  // const [masterList, setMasterList] = useState({
  //   sites: [],
  //   user: {},
  //   company: {},
  // });
  const [masterList, setMasterList] = useState({
    user: {},
    company: {},
    companyList: [],
    siteList: [],
    nodeList: [],
    parameterList: [],
    userList: [],
    zoneList: [],
    gatewayList: [],
    onlineCount: {},

  });
  useEffect(() => {
    const fetchData = async () => {
      const newMasterList = { ...masterList };
      // console.log(sites);

      //   const { data: company } = await getLoggedInCompanyDetails();
      //   newMasterList["company"] = company;
      try {
        // const { data: user } = await getLoggedInUserDetails();
        const user = dummy["user"];
        newMasterList["user"] = user;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch user data");
      }

      try {
        const { data: users } = await getAllUsers();
        newMasterList["userList"] = users;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch user data");
      }

      try {
        const { data: zones } = await getAllZones();
        newMasterList["zoneList"] = zones;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch zone data");
      }

      try {
        const { data: gateways } = await getAllGateway();
        newMasterList["gatewayList"] = gateways;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch gateway data");
      }

      try {
        const { data: parameters } = await getAllParameters();
        newMasterList["parameterList"] = parameters;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch parameter data");
      }

      try {
        const { data: companies } = await getAllCompanies();
        newMasterList["companyList"] = companies;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch parameter data");
      }

      try {
        const { data: nodes } = await getAllNodes();
        newMasterList["nodeList"] = nodes;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch parameter data");
      }

      try {
        const { data: count } = await fetchOnlineCompanyCount();
        newMasterList["onlineCount"] = count;
      } catch (error) {
        console.log(error);
      }

      setMasterList(newMasterList);
    };

    if (masterList["companyList"].length == 0) {
      fetchData();
    }
  }, []);
  return (
    <MasterlistStateContext.Provider value={masterList}>
      {children}
    </MasterlistStateContext.Provider>
  );
}

function useMasterListState() {
  const context = React.useContext(MasterlistStateContext);
  if (context === undefined) {
    throw new Error(
      "useMasterListState must be used within a MasterListProvider"
    );
  }
  return context;
}

export { MasterlistProvider, useMasterListState };
