import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function getAssignedNodes() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/me`);
}

export function getLoggedInUserDetails() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/users/me`);
}

export function getAssignedProcessTags() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/tags/assign/me`);
}

export function getAllCompanies() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/companies`);
}

export function getAllSites() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/sites`);
}

export function getAllNodes() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes`);
}

export function getAllParameters() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/parameters`);
}

export function getAllUsers() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/users`);
}

export function getAllZones() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/zones`);
}

export function getAllGateway() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/gateways`);
}

export function fetchOnlineCompanyCount() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/companies/online`);
}