import { DeleteIcon } from "core/components/svgIcon";
import { ZoneIcon } from "core/components/svgIcon";
import { EditIcon, ParameterIcon } from "core/components/svgIcon";
import { GatewayIcon } from "core/components/svgIcon";
import { Table } from "core/components/table";
import { useConfirmBox } from "core/hooks/confirmHook";
import { useModal } from "core/hooks/modalHook";
import _ from "lodash";
import React, { useState } from "react";

export const NodeTable = ({
  data,
  onEdit,
  onDelete,
  onZoneEdit,
  onGatewayEdit,
  onParameterEdit,
}) => {
  // Edit
  const handleEditAction = (value) => {
    // console.log(value);
    onEdit(value);
  };

  const handleAssignAction = (value) => {
    // console.log(value);
    onZoneEdit(value);
  };

  const handleGatewayAssignAction = (value) => {
    // console.log(value);
    onGatewayEdit(value);
  };

  // Delete Action
  const options = { onSuccess: onDelete };
  const { confirmContainer, openConfirmBox, setSelectedInput } =
    useConfirmBox(options);

  const handleDeleteAction = (value) => {
    setSelectedInput(value);
    openConfirmBox();
  };

  const handleParameterEditAction = (value) => {
    const nodeData = _.pick(value, [
      "id",
      "calibrationParameters",
      "diagnosticParameters",
      "parameters",
      "nodeType",
    ]);
    onParameterEdit(nodeData);
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  const columnDefs = [
    {
      headerName: "Node",
      children: [
        {
          headerName: "Id",
          field: "id",
          maxWidth: 150,
        },
        {
          headerName: "Device Id",
          field: "deviceId",
        },
        {
          headerName: "Slave Id",
          field: "slaveId",
          maxWidth: 150,
        },
        {
          headerName: "Tag Name",
          field: "tagName",
          maxWidth: 150,
        },
        {
          headerName: "Details",
          valueGetter: function (params) {
            return params.data;
          },
          cellRenderer: "actionBtnCellRenderer",
          sortable: false,
          filter: false,
          minWidth: 300,
        },
      ],
    },
  ];

  const ActionButtonRenderer = ({ value }) => {
    return (
      <React.Fragment>
        <span
          title="Edit"
          className="mx-2 is-clickable"
          onClick={() => handleEditAction(value)}
        >
          <EditIcon />
        </span>
        <span
          title="Delete"
          className="mx-2 is-clickable"
          onClick={() => handleDeleteAction(value)}
        >
          <DeleteIcon />
        </span>
        {/* <span
          title="Zone"
          className="mx-2 is-clickable"
          onClick={() => handleAssignAction(value)}
        >
          <ZoneIcon />

        </span> */}
        <span
          title="Gateway"
          className="mx-2 is-clickable"
          onClick={() => handleGatewayAssignAction(value)}
        >
          <GatewayIcon />
        </span>
        <span
          title="Parameters"
          className="mx-2 is-clickable"
          onClick={() => handleParameterEditAction(value)}
        >
          <ParameterIcon />
        </span>
      </React.Fragment>
    );
  };

  const frameworkComponents = {
    actionBtnCellRenderer: ActionButtonRenderer,
  };

  return (
    <React.Fragment>
      <Table
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        paginationAutoPageSize={true}
        pagination={true}
        frameworkComponents={frameworkComponents}
      />
      {confirmContainer}
    </React.Fragment>
  );
};
