import { FormCard } from "core/cards/formCard";
import { TextAreaField } from "core/components/inputWithAccessControl";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { EditTextField } from "core/components/inputWithAccessControl";
import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import Joi from "joi";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { addFeature } from "./featureCrudService";
import { FileUpload } from "core/components/inputWithAccessControl";
import { editFeature } from "./featureCrudService";
import { useMasterListState } from "features/masterList/masterListProvider";

export const FeatureForm = ({
  open,
  onClose,
  selectedData,
  onUpdateSuccess,
}) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    id: Joi.number().label("Id").integer().positive().allow(0),
    name: Joi.string().trim().label("Name").min(3).max(100).required(),
  });
  const accessToEdit = true;
  const submitCallback = async (event) => {
    // console.log(inputs);
    const { id, name } = inputs;
    try {
      startLoading();

      const body = {
        id,
        name,
      };
      // console.log(body);
      if (
        id === null ||
        id === undefined ||
        id === "" ||
        id === "0" ||
        id === 0
      ) {
        const { data: feature } = await addFeature(body);
        onUpdateSuccess(feature);
        toast.success("Feature Added Successfully");
      } else {
        const { data: feature } = await editFeature(id, body);
        onUpdateSuccess(feature);
        toast.success("Feature Edited Successfully");
      }
      //   console.log(companies);
      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error("Error adding / editing node name details");
      stopLoading();
    }
    onClose();
  };
  let defaultInputs = { id: 0 };
  useEffect(() => {
    if (selectedData) {
      handleInputChange("id", selectedData["id"]);
      handleInputChange("name", selectedData["name"]);
    } else {
      resetInput();
      handleInputChange("id", 0);
    }
  }, [selectedData]);
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const FormHtml = (
    <FormCard
      formName={"Add/Update Feature"}
      onSubmit={handleSubmit}
      onFormReset={resetInput}
      submitAccess={accessToEdit}
    >
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="id"
            labelName="Id"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
        </div>

        <div className="column is-one-third">
          <EditTextField
            identifier="name"
            labelName="Name"
            handleInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
      </div>
      {/* /.columns */}
    </FormCard>
  );

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div>{FormHtml}</div>
      {loaderContainer}
    </Modal>
  );
};
