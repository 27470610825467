import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
  
import { FeatureSearch } from "./feature_search";
import { FeatureTable } from "./feature_table";
import { FeatureForm } from "./feature_form";
import { PermissionForm } from "./feature__permission";

import { deleteFeature } from "./featureCrudService";

export const FeatureCrud = () => {
  const [features, setFeatures] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setFeatures(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

  // Delete Action
  const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: feature } = await deleteFeature(data["id"]);
      toast.success("Feature deleted successfully");
      const newList = [...features];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setFeatures(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting feature");
    }
  };

  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(features, { id: selectedId });
      const updatedDataList = [...features];
      updatedDataList[index] = updatedData;
      setFeatures(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...features];
      newList.push(updatedData);
      setFeatures(newList);
    }
  };
  

  const [formModal, openFormModal, closeFormModal] = useModal(false);

  // Permission Modal
  const [permissionModal, openPermissionModal, closePermissionModal] =
    useModal(false);
  const handlePermissionEdit = (data) => {
    setAction("PARAMETER_EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openPermissionModal();
  };


 

  return (
    <React.Fragment>
      <FeatureSearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <FeatureTable
        data={features}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onPermissionEdit={handlePermissionEdit}

      />
      <FeatureForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
      <PermissionForm
        open={permissionModal}
        onClose={closePermissionModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
