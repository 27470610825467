import React from "react";
import { FormCard } from "core/cards/formCard";
import Joi from "joi";
import moment from "moment";
import { useLoading } from "core/hooks/loadingHook";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { getAlertsByDateAndType } from "./alarmHistoryCrudService";
import { DateField } from "core/components/inputWithAccessControl";


export const AlarmHistorySearch = ({ onSearchResults }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const typeOptionArray = ["Battery Alert", "Connectivity Alert"];
  const typeOption = addDefaultOption(formatArrayToOptions(typeOptionArray));

  const schema = Joi.object({
      from: Joi.date().label("From").required(),
      to: Joi.date().label("To").required(),
      type: Joi.string().trim().label("Type").required(),
  });
  const submitCallback = async (event) => {
      const { from, to, type } = inputs;
    //   console.log(inputs);
      const fromUnix = moment(from).format('YYYY-MM-DDTHH:mm:ss');
      const toUnix = moment(to).add(moment.duration("23:59:59")).format('YYYY-MM-DDTHH:mm:ss');
    //   console.log(fromUnix);
    //   console.log(toUnix);
      try {
          startLoading();
         
          const { data: alarms } = await getAlertsByDateAndType(
              fromUnix,
              toUnix,
              type

          );
          onSearchResults(alarms);
          if (alarms.length > 0) {
              toast.success("Data fetched");
          } else {
              toast.warn("No data found");
          }

          stopLoading();
      } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 400) {
              toast.warn("No data found");
          } else {
              toast.error(
                  "Error fetching alerts data. Please refer console for detailed error"
              );
          }
          stopLoading();
      }
  };
  const defaultInputs = {};
  const {
      inputs,
      errors,
      handleInputChange,
      handleSubmit,
      resetInput,
      additionalValidation,
  } = useSubmitForm(schema, submitCallback, defaultInputs);

  const accessToEdit = true;

  


  return (
      <React.Fragment>
          <FormCard
              formName={"Search Alarm History"}
              onSubmit={handleSubmit}
              onFormReset={resetInput}
              submitAccess={true}
              submitText={"Search"}
          >
              <div className="columns">
                  <div className="column">
                      <DateField
                          identifier="from"
                          labelName="From"
                          step={5}
                          editAccess={accessToEdit}
                          handleInputChange={handleInputChange}
                          inputs={inputs}
                          errors={errors}

                          
                      />
                  </div>
                  {/* /.column */}
                  <div className="column">
                      <DateField
                          identifier="to"
                          labelName="To"
                          step={5}
                          editAccess={accessToEdit}
                          handleInputChange={handleInputChange}
                          inputs={inputs}
                          errors={errors}
                      />
                  </div>

                  <div className="column">
                      <SelectContainer
                          identifier="type"
                          labelName="Type"
                          editAccess={accessToEdit}
                          handleInputChange={handleInputChange}
                          inputs={inputs}
                          errors={errors}
                          options={typeOption}
                      // defaultValue={15}
                      ></SelectContainer>
                  </div>
                  
              </div>
              {/* /.columns */}
          </FormCard>
          {loaderContainer}
      </React.Fragment>
  );
};
