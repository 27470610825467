import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchGateway(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/gateways/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/gateways`, {
      params: body,
    });
  }

  export function addGateway(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.post(`${base}/gateways`, body);
  }
  
  export function editGateway(id, body) {
    const base = globalConfig.get().apiEndpoint;
    return http.put(`${base}/gateways/${id}`, body);
  }

  export function deleteGateway(id) {
    const base = globalConfig.get().apiEndpoint;
    return http.delete(`${base}/gateways/delete/${id}`);
  }
  
  export function fetchAllgateways() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/gateways`);
  }

