import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

export function searchAlarmHistory(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/alarms/search`, {
      params: body,
    });
  }
  
  export function findAll(body) {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/alarms`, {
      params: body,
    });
  }

  export function getAlertsByDateAndType(from, to, type) {
    const base = globalConfig.get().apiEndpoint;
return http.post( `${base}/alarms/search/date`,
      {
        from: from,
        to: to,
        type: type,
      }
    );
  }
  

 


  
  

