import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { CompanyCrud } from "features/company/companyCrud";

export const CompanyAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"company"}
          description={"Interface for handling companies"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Company"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            <CompanyCrud/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
