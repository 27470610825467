import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { toast } from "react-toastify";
import { deleteZone } from "./zoneCrudService";
import { ZoneForm } from "./zone__form";
import { ZoneSearch } from "./zone__search";
import { ZoneTable } from "./zone__table";

export const ZoneCrud = () => {
  const [zones, setZones] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  const handleSearchResults = (output) => {
    // console.log(output);
    setZones(output);
  };

  // Add Action
  const handleAddNew = () => {
    setAction("ADD");
    setSelectedId(0);
    setSelectedInput({});
    openFormModal();
  };

  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    setSelectedInput(data);
    openFormModal();
  };

  // Delete Action
  const handleDelete = async (data) => {
    // console.log(data);
    try {
      setAction("DELETE");
      setSelectedId(data["id"]);
      setSelectedInput(data);
      const { data: zone } = await deleteZone(data["id"]);
      toast.success("Zone deleted successfully");
      const newList = [...zones];
      _.remove(newList, function (n) {
        // console.log("Checking " + data["id"] + " " + n["id"]);
        return n["id"] == data["id"];
      });
      // console.log(newList);
      setZones(newList);
    } catch (error) {
      console.log(error);
      toast.error("Error deleting zone");
    }
  };

  // Update on success
  const handleAddUpdateSuccess = (updatedData) => {
    if (action === "EDIT") {
      const index = _.findIndex(zones, { id: selectedId });
      const updatedDataList = [...zones];
      updatedDataList[index] = updatedData;
      setZones(updatedDataList);
    } else if (action === "ADD") {
      const newList = [...zones];
      newList.push(updatedData);
      setZones(newList);
    }
  };

  const [formModal, openFormModal, closeFormModal] = useModal(false);

 

  return (
    <React.Fragment>
      <ZoneSearch
        onSearchResults={handleSearchResults}
        onAddNew={handleAddNew}
      />
      <ZoneTable
        data={zones}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <ZoneForm
        open={formModal}
        onClose={closeFormModal}
        selectedData={selectedInput}
        onUpdateSuccess={handleAddUpdateSuccess}
      />
     
    </React.Fragment>
  );
};
