import React from "react";
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { DataGenerationForm } from "features/dataGeneration/dataGeneration__form";

export const DataGenerationAction = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Data Generation"}
          description={"Interface for handling data correction"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Data Generation"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            <DataGenerationForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
