import { CrossIcon } from "core/components/svgIcon";
import { useModal } from "core/hooks/modalHook";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { AlertModal } from "./alertModal";

const AlertModalStateContext = React.createContext();

function AlertModalProvider({ children }) {
  const [open, openAlertModal, closeAlertModal] = useModal(false);

  const [message, setMessage] = useState(false);
  const showMessage = (text) => {
    setMessage(text);
    openAlertModal();
  };
  return (
    <AlertModalStateContext.Provider value={showMessage}>
      <AlertModal open={open} onClose={closeAlertModal} width="full-width">
        <article className="message is-danger">
          <div className="message-header">
            <p>Alert</p>
            <span className="is-clickable" onClick={closeAlertModal}>
              <CrossIcon />
            </span>
          </div>
          <div className="message-body">{message}</div>
        </article>
      </AlertModal>
      {children}
    </AlertModalStateContext.Provider>
  );
}

function useAlertModalState() {
  const context = React.useContext(AlertModalStateContext);
  if (context === undefined) {
    throw new Error(
      "useAlertModalState must be used within a PermissionProvider"
    );
  }
  return context;
}

// function usePermissionDispatchState() {
//   const context = React.useContext(PermissionDispatchContext);
//   if (context === undefined) {
//     throw new Error(
//       "usePermissionDispatchState must be used within a PermissionProvider"
//     );
//   }
//   return context;
// }

export { AlertModalProvider, useAlertModalState };
