import { Modal } from "core/components/modal";
import { useModal } from "./modalHook";
import React, { useState } from "react";

export const useConfirmBox = (options) => {
  const { onSuccess, onFailure, message = "Do you want to proceed?" } = options;
  const [confirmBox, openConfirmBox, closeConfirmBox] = useModal(false);
  const [selectedInput, setSelectedInput] = useState();
  const onSuccessClick = () => {
    if (selectedInput) {
      onSuccess(selectedInput);
    } else {
      onSuccess();
    }
    closeConfirmBox();
  };
  const onDeniedClick = () => {
    if (onFailure) {
      onFailure();
    }
    closeConfirmBox();
  };

  const confirmContainer = (
    <React.Fragment>
      <Modal open={confirmBox} onClose={closeConfirmBox}>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title is-size-6">Confirm Box</p>
          </header>
          {/* /.card-header */}
          <div className="card-content">
            <div>
              <p className="subtitle">{message}</p>
              <button className="button is-danger mx-2" onClick={onDeniedClick}>
                No
              </button>
              <button
                className="button is-success mx-2"
                onClick={onSuccessClick}
              >
                Yes!
              </button>
            </div>
          </div>
        </div>
        {/* /.card */}
      </Modal>
    </React.Fragment>
  );

  return {
    confirmContainer,
    openConfirmBox,
    setSelectedInput,
  };
};
